import React from "react"
import BackToDash from "../backToDash/backToDash"
import Ventas from "./ventas"

import { connect } from 'react-redux'
import { get_sales } from '../../../redux/actions/index'

import Loader from '../../../components/loader/loader'

import './ventas.css'

class AdminVentas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            loading: true,
            sales: []
        }
    }

    componentDidMount() {
        console.log('GET SALES')
        this.props.get_sales()
        this.setState({
            user: this.props.user
        }, () => {
            if (!this.state.user) {
                window.location.hash = '/'
            } else {
                if (!this.state.user.admin) {
                    window.location.hash = '/'
                }
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            this.setState({
                user: this.props.user
            }, () => {
                if (!this.state.user) {
                    window.location.href = '/'
                } else {
                    if (!this.state.user.admin) {
                        window.location.href = '/'
                    }
                }
            })
        }

        if (prevState.user !== this.state.user) {
            if (this.state.user) {
                if (this.state.user.admin) {
                    this.setState({
                        loading: false
                    })
                }
            }
        }

        if (prevProps.state.sales !== this.props.state.sales) {
            this.setState({
                sales: this.props.state.sales,
                loading: false
            }, () => {
                console.log('sales: ', this.state.sales)
            })
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="dashboard-main-container">
                    <BackToDash />
                    <h1 className="dashboard-title">
                        Administrar ventas
                    </h1>
                    <Ventas
                        sales={this.state.sales} />
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    get_sales
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(AdminVentas);