import { STATUS_COL, PRODUCTS, REGISTER, STATUS, USER, EDITORIALES, VENTAS, SERIE, REGISTER_STATUS, PRODUCT, TICKET, DATA, VOLUMES, SALES } from '../actions/index'

const initialState = {
    status: null,
    products: [],
    user: null,
    register_status: null,
    editoriales: [],
    ventas: [],
    error: null,
    serie: {},
    register: null,
    product: {},
    colecciones: [],
    ticket: [],
    data: {},
    volumes: [],
    sales: []
}

export default function reducer(state = initialState, actions){
    switch(actions.type){
        case PRODUCTS:
            return{
                ...state,
                products: actions.payload
            }
        case USER:
            return{
                ...state,
                user: actions.payload
            }
        case STATUS:
            return{
                ...state,
                status: actions.payload,
                error: actions.error
            }
        case REGISTER_STATUS:
            return{
                ...state,
                register_status: actions.payload
            }
        case REGISTER:
            return{
                ...state,
                register: actions.payload
            }
        case EDITORIALES:
            return{
                ...state,
                editoriales: actions.payload
            }
        case VENTAS:
            return{
                ...state,
                ventas: actions.payload
            }
        case SERIE:
            return{
                ...state,
                serie: actions.payload
            }
        case PRODUCT:
            return{
                ...state,
                product: actions.payload
            }
        case STATUS_COL:
            return{
                ...state,
                colecciones: actions.payload
            }
        case TICKET:
            return{
                ...state,
                ticket: actions.payload
            }
        case DATA:
            return{
                ...state,
                data: actions.payload
            }
        case VOLUMES:
            return{
                ...state,
                volumes: actions.payload
            }
            case SALES:
                return{
                    ...state,
                    sales: actions.payload
                }
        default:
            return state
    }
}