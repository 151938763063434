import React from 'react'
import Card from './card/card'

class Ventas extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            sales: [],
            shipping: [],
            local_delivery: [],
            selected: "ship"
        }
    }

    setVentas = () => {
        const ventas = this.state.sales
        const despachar = []
        const retirar = []

        for(let i = 0; i < ventas.length; i++){
            if(ventas[i].shipping){
                despachar.push(ventas[i])
            }else if(ventas[i].local_delivery){
                retirar.push(ventas[i])
            }
        }

        this.setState({
            local_delivery: retirar,
            shipping: despachar
        })
    }

    configState = () => {
        this.setState({
            sales: this.props.sales
        })
    }

    componentDidMount(){
        this.configState()
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps !== this.props){
            this.configState()
        }

        if(prevState.sales !== this.state.sales && this.state.sales){
            this.setVentas()
        }
    }

    getStyle = (e) => {
        if(e === this.state.selected){
            return {"background-color":"rgb(55, 130, 175)"}
        }else{
            return {"background-color":"white"}
        }
    }

    changeSelected = (e) => {
        this.setState({
            selected: e.target.id
        })
    }

    getVentas = () => {
        if(this.state.selected === "local"){
            return(
                <section className='ventas-grid'>
                    {
                        this.state.local_delivery.map((v)=>{
                            let venta = v
                            venta.retirar = true
                            return <Card venta={venta} />
                        })
                    }
                </section>
            )
        }else{
            return(
                <section className='ventas-grid'>
                    {
                        this.state.shipping.map((v)=>{
                            let venta = v
                            return <Card venta={venta} />
                        })
                    }
                </section>
            )
        }
    }

    render(){
        return(
            <div className='ventas-main-container'>
                <span className='ventas-buttons'>
                    <button className='ventas-button'
                    id="local"
                    style={this.getStyle("local")}
                    onClick={this.changeSelected}>
                        Ventas por retirar
                    </button>
                    <button className='ventas-button'
                    id="ship"
                    style={this.getStyle("ship")}
                    onClick={this.changeSelected}>
                        Ventas por despachar
                    </button>
                </span>
                {this.getVentas()}
            </div>
        )
    }
}

export default Ventas