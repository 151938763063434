import axios from 'axios'

import tokens from '../../variables'
import { updateGuestCart, getGuestCart, setGuestDiscount } from './guestCartFunctions'
import { auth } from '../../firebase'
import Swal from 'sweetalert2'

export const PRODUCTS = "PRODUCTS"
export const USER = "USER"
export const STATUS = "STATUS"
export const REGISTER = "REGISTER"
export const EDITORIALES = "EDITORIALES"
export const VENTAS = "VENTAS"
export const SERIE = "SERIE"
export const REGISTER_STATUS = "REGISTER_STATUS"
export const PRODUCT = "PRODUCT"
export const STATUS_COL = "STATUS_COL"
export const TICKET = "TICKET"
export const DATA = "DATA"
export const ERROR = "ERROR_STATUS"
export const VOLUMES = "VOLUMES"
export const SALES = "SALES"


//GET PRODUCTS
export function get_home_products() { //migrado
    return function (dispatch) {
        axios.get(`${tokens.server}/products/home`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_figuras() {
    return function (dispatch) {
        axios.get(`${tokens.server}/figuras`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_mangas_products(limit, bs_limit) { //migrado
    return function (dispatch) {
        axios.get(`${tokens.server}/mangas/page?limit=${limit}&bs_limit=${bs_limit}`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_comics_products(limit, bs_limit) { //migrado
    return function (dispatch) {
        axios.get(`${tokens.server}/comics/page?limit=${limit}&bs_limit=${bs_limit}`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_libros_products(limit, bs_limit) { //migrado
    return function (dispatch) {
        axios.get(`${tokens.server}/libros/page?limit=${limit}&bs_limit=${bs_limit}`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_serie(id) { //migrado
    return function (dispatch) {
        axios.get(`${tokens.server}/products/serie/${id}`)
            .then((response) => {
                if (response.status === 200)
                    dispatch({
                        type: SERIE,
                        payload: {
                            data: response.data,
                            status: response.status
                        }
                    })
                if (response.status === 204)
                    dispatch({
                        type: SERIE,
                        payload: {
                            data: null,
                            status: response.status
                        }
                    })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_products() {
    return function (dispatch) {
        axios.get(`${tokens.server}/products`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_author(author) {
    return function (dispatch) {
        axios.get(`${tokens.server}/products/author/${author}`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function search_products(query) {
    return function (dispatch) {
        axios.get(`${tokens.server}/products/search/${query}`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_product(id) { //migrado
    return function (dispatch) {
        axios.get(`${tokens.server}/products/${id}`)
            .then((response) => {
                dispatch({
                    type: PRODUCT,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

//----------------------------------------------------------------------------------------
//GET EDITORIAL
export function get_editorial(id) {
    return function (dispatch) {
        axios.get(`${tokens.server}/editoriales/page/${id}`)
            .then((response) => {
                dispatch({
                    type: EDITORIALES,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}


export function get_editoriales() {
    return function (dispatch) {
        axios.get(`${tokens.server}/editoriales`)
            .then((response) => {
                dispatch({
                    type: EDITORIALES,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function add_editorial(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/editoriales`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

/* USER */

export function getUser(id) {
    return function (dispatch) {
        axios.get(`${tokens.server}/user/${id}`)
            .then((response) => {
                dispatch({
                    type: USER,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function userAvailable(user) {
    return function (dispatch) {
        axios.get(`${tokens.server}/user/available/${user}`)
            .then((response) => {
                dispatch({
                    type: REGISTER_STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function register(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/user/register`, data)
            .then((response) => {
                dispatch({
                    type: REGISTER,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

/* CART */
export function get_cart_items() { //migrado
    return async function (dispatch) {
        if (auth.currentUser) {
            const token = await auth.currentUser.getIdToken()
            axios.get(`${tokens.server}/cart`,
                { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    dispatch({
                        type: PRODUCTS,
                        payload: response.data
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: ERROR,
                        payload: error.response.data
                    })
                })
        } else {
            const cart = getGuestCart()
            axios.post(`${tokens.server}/cart/guest`, { cart: cart })
                .then((response) => {
                    dispatch({
                        type: PRODUCTS,
                        payload: response.data
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: ERROR,
                        payload: error.response.data
                    })
                })
        }
    }
}

export function change_cart_cant(data) { //migrado
    if (!auth.currentUser?.uid) {
        const status = updateGuestCart(data.id, (data.new_cant - data.cant))
        let msg = 'success'
        if (!status)
            msg = 'Ha ocurrido un error a la hora de actualizar el carrito. Por favor, vuelva a intentarlo.'
        return function (dispatch) {
            return dispatch({
                type: STATUS,
                payload: status,
                error: msg
            })
        }
    }

    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken()
        axios.put(`${tokens.server}/cart`, { product: data.id, qty: (data.new_cant - data.cant) },
            { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function add_cart_item(data) { //migrado
    if (!data.user) {
        const status = updateGuestCart(data.product, 1)
        let msg = 'success'
        if (!status)
            msg = 'Ha ocurrido un error a la hora de actualizar el carrito. Por favor, vuelva a intentarlo.'
        return function (dispatch) {
            return dispatch({
                type: STATUS,
                payload: status,
                error: msg
            })
        }
    }

    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken()

        axios.post(`${tokens.server}/cart`, { product: data.product }, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }

}

export function apply_discount(code) { //migrado
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken() || null

        if (token) {
            axios.post(`${tokens.server}/cart/discount?code=${code}`, { headers: { 'Authorization': `Bearer ${token}` } })
                .then((response) => {
                    dispatch({
                        type: STATUS,
                        payload: response.data
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: ERROR,
                        payload: error.response.data
                    })
                })
        } else {
            axios.get(`${tokens.server}/cart/discount?code=${code}`)
                .then((response) => {
                    setGuestDiscount(response.data)
                    dispatch({
                        type: STATUS,
                        payload: response.data
                    })
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'El código de descuento no es válido'
                    })
                })
        }

    }

}

/* FAVORITOS */
export function addToFavs(data) {
    return function (dispatch) {
        if (data.user) {
            axios.post(`${tokens.server}/add_to_favs`, data)
                .then((response) => {
                    dispatch({
                        type: STATUS,
                        payload: response.data
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: ERROR,
                        payload: error.response.data
                    })
                })
        } else {
            return dispatch({
                type: STATUS,
                payload: false,
                error: "Tenés que iniciar sesión para usar los favoritos!"
            })
        }
    }
}

/* ADMIN */
/* MANGAS */
export function add_manga(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/mangas/series`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_series_manga() {
    return function (dispatch) {
        axios.get(`${tokens.server}/mangas/series`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function add_manga_vol(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/mangas/tomo`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

/* CÓMICS */
export function add_comic(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/comics/series`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_series_comic() {
    return function (dispatch) {
        axios.get(`${tokens.server}/comics/series`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function add_comic_vol(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/comics/tomo`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

/* COLECCIONES */
export function add_col(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/colecciones/series`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_series_col() {
    return function (dispatch) {
        axios.get(`${tokens.server}/colecciones/series`)
            .then((response) => {
                dispatch({
                    type: STATUS_COL,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: STATUS_COL,
                    payload: error.response.data
                })
            })
    }
}

export function add_col_vol(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/colecciones/tomo`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

/* LIBROS */
export function add_libro(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/libros/series`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_series_libro() {
    return function (dispatch) {
        axios.get(`${tokens.server}/libros/series`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function add_libro_vol(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/libros/tomo`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

/* FIGURAS */
export function add_figura(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/figuras`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

/* STOCK */
export function getStockProducts() {
    return function (dispatch) {
        axios.get(`${tokens.server}/stock`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_series_stock(type) {
    return function (dispatch) {
        axios.get(`${tokens.server}/stock/series?type=${type}`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_volumes_stock(serie) {
    return function (dispatch) {
        console.log(serie)
        axios.get(`${tokens.server}/stock/volumes?serie=${serie}`)
            .then((response) => {
                dispatch({
                    type: VOLUMES,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function change_stock(data) {
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken() || null
        axios.patch(`${tokens.server}/stock`, data, { headers: { 'Authorization': `Bearer ${token || ''}` } })
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

/* VENTAS */
export function get_ventas() {
    return function (dispatch) {
        axios.get(`${tokens.server}/get_ventas`)
            .then((response) => {
                dispatch({
                    type: VENTAS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function entregada(id) {
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken() || null
        axios.patch(`${tokens.server}/sales/${id}`, null, { headers: { 'Authorization': `Bearer ${token || ''}` } })
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function despachada(data) {
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken() || null
        axios.patch(`${tokens.server}/sales/${data.id}/dispatch`, { track_id: data.seguimiento }, { headers: { 'Authorization': `Bearer ${token || ''}` } })
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function simul_venta(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/ventas/simul`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_ticket(data) {
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken() || 'anonimo'
        axios.post(`${tokens.server}/sales/ticket`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                dispatch({
                    type: TICKET,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function change_price(data) {
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken()
        axios.patch(`${tokens.server}/price`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_sales() {
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken()
        axios.get(`${tokens.server}/sales`, { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                dispatch({
                    type: SALES,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_series_price() {
    return function (dispatch) {
        axios.get(`${tokens.server}/price/series`)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_volumes_price(serie) {
    return function (dispatch) {
        axios.get(`${tokens.server}/price/volumes?serie=${serie}`)
            .then((response) => {
                dispatch({
                    type: VOLUMES,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function advanced_search(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/advanced_search`, data)
            .then((response) => {
                dispatch({
                    type: PRODUCTS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function checkout(data) {
    return function (dispatch) {
        axios.post(`${tokens.server}/checkout`, data)
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function get_checkout_data() {
    return function (dispatch) {
        axios.get(`${tokens.server}/checkout`,)
            .then((response) => {
                dispatch({
                    type: DATA,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function add_publisher_discount(data) {
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken()
        axios.post(`${tokens.server}/discount/publisher`, data,
            { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export function add_discount_code(data) {
    return async function (dispatch) {
        const token = await auth.currentUser?.getIdToken()
        axios.post(`${tokens.server}/discount/code`, data,
            { headers: { 'Authorization': `Bearer ${token}` } })
            .then((response) => {
                dispatch({
                    type: STATUS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}